import React from 'react';
import DelayRender from '../ui/DelayRender';
import Spinner from '../ui/Spinner';

const LoadingPage = () => (
  <DelayRender>
    <Spinner />
  </DelayRender>
);

export default LoadingPage;
