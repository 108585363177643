import createPersistedState from '@paperstac/hooks/lib/createPersistedState';
import React from 'react';
import useMedia from 'use-media';
import theme from '../theme';

const DEFAULT_STATE = {
  isMobile: false,
  isMobileOrLarger: false,
  isTablet: false,
  isTabletOrLarger: false,
  isWideTablet: false,
  isWideTabletOrLarger: false,
  isDesktop: false,
  isDesktopOrLarger: false,
  isWideDesktop: false,
};

const usePersistedState = createPersistedState('useBreakpoints', sessionStorage);

const useBreakpoints = () => {
  const [state, setState] = usePersistedState(DEFAULT_STATE);

  const isMobileOrLarger = useMedia({ minWidth: theme.breakpoints.mobile });
  const isTabletOrLarger = useMedia({ minWidth: theme.breakpoints.tablet });
  const isWideTabletOrLarger = useMedia({ minWidth: theme.breakpoints.wideTablet });
  const isDesktopOrLarger = useMedia({ minWidth: theme.breakpoints.desktop });
  const isWideDesktop = useMedia({ minWidth: theme.breakpoints.wideDesktop });

  React.useEffect(() => {
    if (!isMobileOrLarger) return;
    setState({
      isMobile: isMobileOrLarger && !isTabletOrLarger,
      isMobileOrLarger,
      isTablet: isTabletOrLarger && !isWideTabletOrLarger,
      isTabletOrLarger,
      isWideTablet: isWideTabletOrLarger && !isDesktopOrLarger,
      isWideTabletOrLarger,
      isDesktop: isDesktopOrLarger && !isWideDesktop,
      isDesktopOrLarger,
      isWideDesktop,
    });
  }, [isMobileOrLarger, isTabletOrLarger, isWideTabletOrLarger, isDesktopOrLarger, isWideDesktop, setState]);

  return state;
};

export default useBreakpoints;
