import PropTypes from 'prop-types';
import React from 'react';

const DelayRender = React.memo(({ ms, children }) => {
  const [showing, setShowing] = React.useState();
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowing(true);
    }, ms);
    return () => timeoutId && clearTimeout(timeoutId);
  });

  return showing ? children : null;
});

DelayRender.propTypes = {
  /** Delay in milliseconds */
  ms: PropTypes.number,
};

DelayRender.defaultProps = {
  ms: 1000,
};

DelayRender.displayName = 'DelayRender';

export default DelayRender;
