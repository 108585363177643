import { HOME } from '@paperstac/routes/lib/uiRoutes';
import React from 'react';
import { Link } from 'react-router-dom';
import useMedia from 'use-media';
import { ReactComponent as Branding } from '../../images/paperstac.svg';
import Box from '../ui/Box';
import Container from '../ui/Container';
import Flex from '../ui/Flex';
import useBreakpoints from '../ui/hooks/useBreakpoints';

const Header = React.memo((props) => {
  const { isMobile } = useBreakpoints();
  const isTall = useMedia({ minHeight: 800 });

  return (
    <Box
      bg="white"
      position={isTall ? 'sticky' : 'relative'}
      pt={[3, 3, 5]}
      pb={[50, 53, 5]}
      style={{
        top: 0,
        zIndex: 3,
      }}
      width="100vw"
    >
      <Container as={Flex} alignItems="center">
        <Box flex={1}>
          <Link to={HOME}>
            <Branding height={isMobile ? 24 : 30} style={{ verticalAlign: 'middle' }} />
          </Link>
        </Box>
        <Box>Select goes here</Box>
      </Container>
    </Box>
  );
});

Header.propTypes = {};

Header.defaultProps = {};

Header.displayName = 'Header';

export default Header;
