import React, { useState } from 'react';
import { createGlobalStyle, ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';
import { mergeDeep } from './helpers';
import Tabs from './Tabs';
import defaultTheme from './theme';
import { InternationalPhoneInputStyles } from './form/InternationalPhoneInput';

const GlobalStyle = createGlobalStyle`
*, *::before, *::after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: ${defaultTheme.fonts.body};
  font-size: 14px;
  line-height: 1.5;
  color: ${defaultTheme.colors.text};
  background-color: ${defaultTheme.colors.background};
}

@media (min-width: ${defaultTheme.breakpoints[0]}) {
  body {
    font-size: 16px;
  }
}
`;

const ThemeContext = React.createContext();
const { Provider } = ThemeContext;

const ThemeProvider = (props) => {
  const [theme] = useState(mergeDeep({}, defaultTheme, props.theme));

  return (
    <StyledComponentsThemeProvider theme={theme}>
      <Provider value={{ theme }}>
        {/* We wrap content in <Tabs> since unmanaged <Tab> components still require importing a Tabs context */}
        <Tabs>
          <Normalize />
          <GlobalStyle />
          <InternationalPhoneInputStyles />
          {props.children}
        </Tabs>
      </Provider>
    </StyledComponentsThemeProvider>
  );
};

ThemeProvider.defaultProps = {
  theme: {},
};

export default ThemeProvider;

export { ThemeContext };
