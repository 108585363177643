export var AVATAR = '/components/Avatar';
export var BOX = '/components/Box';
export var BUTTON = '/components/Button';
export var CARD = '/components/Card';
export var CAROUSEL = '/components/Carousel';
export var CHAT = '/components/Chat';
export var CHAT_MESSAGE = '/components/ChatMessage';
export var CODE = '/components/Code';
export var CONFIRM_POPOVER = '/components/ConfirmPopover';
export var DELAY_RENDER = '/components/DelayRender';
export var FORM_COPY_INPUT = '/components/form/CopyInput';
export var FORM_INPUT = '/components/form/Input';
export var FORM_INPUT_FORM_GROUP = '/components/form/InputFormGroup';
export var FORM_INTERNATIONAL_PHONE_INPUT = '/components/form/InternationalPhoneInput';
export var FORM_RADIO = '/components/form/Radio';
export var HOME = '/';
export var IMAGE = '/components/Image';
export var LINK = '/components/Link';
export var LIST_GRID = '/components/ListGrid';
export var LIST_GROUP = '/components/ListGroup';
export var OVERLAY = '/components/Overlay';
export var STACK = '/components/Stack';
export var SPREADSHEET = '/components/Spreadsheet';
export var TABLE = '/components/Table';
