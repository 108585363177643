import React, { Component, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Header from './components/common/Header';
import Routes from './components/common/Routes';
import LoadingPage from './components/pages/LoadingPage';
import Box from './components/ui/Box';
import Flex from './components/ui/Flex';
import ScrollToTop from './components/ui/ScrollToTop';
import ThemeProvider from './components/ui/ThemeProvider';

class App extends Component {
  render() {
    return (
      <ThemeProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <ScrollToTop>
            <Suspense fallback={<LoadingPage />}>
              <Flex flexDirection="column" minHeight="100vh" bg="gray.2">
                <Header />
                <Box as="main" flex="1 1 0" width="100%">
                  <Routes />
                </Box>
              </Flex>
            </Suspense>
          </ScrollToTop>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

export default App;
