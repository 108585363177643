import { rem } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rotate from './rotate';

const getSpinnerSize = (size) => {
  switch (size) {
    case 'tiny':
      return 14;
    case 'small':
      return 20;
    case 'default':
      return 40;
    case 'large':
      return 75;
    default:
      return 20;
  }
};

const getSpinnerThickness = (size) => {
  switch (size) {
    case 'tiny':
      return 2;
    case 'small':
      return 3;
    case 'default':
      return 6;
    case 'large':
      return 10;
    default:
      return 3;
  }
};

const Spinner = styled.div`
  display: inline-block;
  width: ${(props) => rem(getSpinnerSize(props.size))};
  height: ${(props) => rem(getSpinnerSize(props.size))};
  border-width: ${(props) => rem(getSpinnerThickness(props.size))};
  border-color: ${(props) => props.borderColor || props.theme.colors.secondary};
  border-style: solid;
  border-top-color: transparent;
  animation: ${rotate} 1s linear infinite;
  border-radius: 50%;
`;

Spinner.propTypes = {
  /** Size of button */
  size: PropTypes.oneOf(['tiny', 'small', 'default', 'large']),
};

Spinner.defaultProps = {
  size: 'default',
};

Spinner.displayName = 'Spinner';

export default Spinner;
