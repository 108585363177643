import React from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop = withRouter(
  React.memo(({ children, location }) => {
    React.useEffect(() => window.scrollTo(0, 0), [location.pathname]);
    return children;
  })
);

ScrollToTop.propTypes = {};

ScrollToTop.defaultProps = {};

ScrollToTop.displayName = 'ScrollToTop';

export default ScrollToTop;
