import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

const TabsContext = React.createContext();
const { Provider } = TabsContext;

class Tabs extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      initialSelected: props.selected,
      selected: props.selected,
      handleSelect: this.setSelected,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return props.selected !== state.initialSelected ? { selected: props.selected } : null;
  }

  setSelected = (selected) => {
    this.setState({ selected });
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

Tabs.propTypes = {
  /** Tab id of selected tab */
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
Tabs.defaultProps = {};
Tabs.displayName = 'Tabs';

export default Tabs;

export { TabsContext };
