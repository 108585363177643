import * as routes from '@paperstac/routes/lib/uiRoutes';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

const Avatar = React.lazy(() => import('../pages/AvatarPage'));
const Box = React.lazy(() => import('../pages/BoxPage'));
const Button = React.lazy(() => import('../pages/ButtonPage'));
const Card = React.lazy(() => import('../pages/CardPage'));
const Carousel = React.lazy(() => import('../pages/CarouselPage'));
const Chat = React.lazy(() => import('../pages/ChatPage'));
const ChatMessage = React.lazy(() => import('../pages/ChatMessagePage'));
const Code = React.lazy(() => import('../pages/CodePage'));
const ConfirmPopover = React.lazy(() => import('../pages/ConfirmPopoverPage'));
const DelayRender = React.lazy(() => import('../pages/DelayRenderPage'));
const FormCopyInput = React.lazy(() => import('../pages/FormCopyInputPage'));
const FormInput = React.lazy(() => import('../pages/FormInputPage'));
const FormInputFormGroup = React.lazy(() => import('../pages/FormInputFormGroupPage'));
const FormInternationalPhoneInput = React.lazy(() => import('../pages/FormInternationalPhoneInputPage'));
const FormRadio = React.lazy(() => import('../pages/FormRadioPage'));
const Home = React.lazy(() => import('../pages/HomePage'));
const Image = React.lazy(() => import('../pages/ImagePage'));
const Link = React.lazy(() => import('../pages/LinkPage'));
const ListGrid = React.lazy(() => import('../pages/ListGridPage'));
const ListGroup = React.lazy(() => import('../pages/ListGroupPage'));
const Overlay = React.lazy(() => import('../pages/OverlayPage'));
const Spreadsheet = React.lazy(() => import('../pages/SpreadsheetPage'));
const Stack = React.lazy(() => import('../pages/StackPage'));
const Table = React.lazy(() => import('../pages/TablePage'));

const Routes = React.memo(() => (
  <Switch>
    <Route exact path={routes.AVATAR} component={Avatar} />
    <Route exact path={routes.BOX} component={Box} />
    <Route exact path={routes.BUTTON} component={Button} />
    <Route exact path={routes.CARD} component={Card} />
    <Route exact path={routes.CAROUSEL} component={Carousel} />
    <Route exact path={routes.CHAT} component={Chat} />
    <Route exact path={routes.CHAT_MESSAGE} component={ChatMessage} />
    <Route exact path={routes.CODE} component={Code} />
    <Route exact path={routes.CONFIRM_POPOVER} component={ConfirmPopover} />
    <Route exact path={routes.DELAY_RENDER} component={DelayRender} />
    <Route exact path={routes.FORM_COPY_INPUT} component={FormCopyInput} />
    <Route exact path={routes.FORM_INPUT} component={FormInput} />
    <Route exact path={routes.FORM_INPUT_FORM_GROUP} component={FormInputFormGroup} />
    <Route exact path={routes.FORM_INTERNATIONAL_PHONE_INPUT} component={FormInternationalPhoneInput} />
    <Route exact path={routes.FORM_RADIO} component={FormRadio} />
    <Route exact path={routes.HOME} component={Home} />
    <Route exact path={routes.IMAGE} component={Image} />
    <Route exact path={routes.LINK} component={Link} />
    <Route exact path={routes.LIST_GRID} component={ListGrid} />
    <Route exact path={routes.LIST_GROUP} component={ListGroup} />
    <Route exact path={routes.OVERLAY} component={Overlay} />
    <Route exact path={routes.SPREADSHEET} component={Spreadsheet} />
    <Route exact path={routes.STACK} component={Stack} />
    <Route exact path={routes.TABLE} component={Table} />
  </Switch>
));

export default Routes;
