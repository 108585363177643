import get from 'lodash/get';
import React from 'react';
import Box from './Box';
import theme from './theme';

export const Container = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    {...props}
    __css={{
      mx: 'auto',
      px: [3, 4, 5, 6],
      width: '100%',
      maxWidth: get(theme, 'sizes.containerMax'),
    }}
  />
));

Container.displayName = 'Container';

export default Container;
